import React from "react";
import styled from "styled-components";

import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {TypographyUI} from "@ui";
import srcImg1 from "@images/find-selfworkers/WhyMarketSectionImage1.png";
import srcImg2 from "@images/find-selfworkers/WhyMarketSectionImage2.png";
import srcImg3 from "@images/find-selfworkers/WhyMarketSectionImage3.png";

export const WhyMarketSection = () => (
    <SectionBoxSd>
        <TextBoxSd>
            <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>
                Хороший специалист — тот, которому вы доверяете
            </TypographyUI.H2>
            <TypographyUI.Body1Regular swichPoint={LAPTOP_MOBILE_POINTER}>
                В базе данных самозанятых только проверенные специалисты со всей России
            </TypographyUI.Body1Regular>
        </TextBoxSd>
        <SectionItems>
            <SectionItem>
                <SectionItemContent>
                    <TypographyUI.H4Bold className="title">Почему самозанятые</TypographyUI.H4Bold>
                    <TypographyUI.Body1Regular className="description">Все исполнители Маркетплейса прошли регистрацию в
                        ФНС — они дорожат репутацией и не исчезнут после аванса. А вам не придется уплачивать за них
                        налоги и страховые взносы</TypographyUI.Body1Regular>
                </SectionItemContent>
                <SectionItemImage>
                    <img src={srcImg1} alt={''}/>
                </SectionItemImage>
            </SectionItem>

            <SectionItem>
                <SectionItemContent>
                    <TypographyUI.H4Bold className="title">Как мы проверяем исполнителей</TypographyUI.H4Bold>
                    <TypographyUI.Body1Regular className="description">При регистрации пользователи указывают ИНН — так
                        мы проверяем статус самозанятого. Если исполнитель его теряет, то больше не может выполнять
                        задания</TypographyUI.Body1Regular>
                </SectionItemContent>
                <SectionItemImage>
                    <img src={srcImg2} alt={''}/>
                </SectionItemImage>
            </SectionItem>

            <SectionItem>
                <SectionItemContent>
                    <TypographyUI.H4Bold className="title">Как платить самозанятому</TypographyUI.H4Bold>
                    <TypographyUI.Body1Regular className="description">Наличными, картой или с расчетного счета.
                        Выберите любой удобный способ оплаты, а мы подготовим все документы</TypographyUI.Body1Regular>
                </SectionItemContent>
                <SectionItemImage>
                    <img src={srcImg3} alt={''}/>
                </SectionItemImage>
            </SectionItem>

        </SectionItems>
    </SectionBoxSd>
);

const SectionBoxSd = styled.section`
    display: flex;
    transition: all 0.3s;
    padding-top: 200px;
    flex-direction: row;
    gap: 71px;
    padding-bottom: 150px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-top: 80px;
        padding-bottom: 60px;
        flex-direction: column;
        gap: 0;
    }
`;

const TextBoxSd = styled.div`
    max-width: 417px;
    width: 100%;
    margin-bottom: 56px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
        margin-bottom: 40px;
        gap: 16px;
    }
`;

const SectionItems = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
`;


const SectionItem = styled.div`
    background: var(--background-main-grey);
    padding: 24px 37px 24px 28px;
    border-radius: 20px;
    display: flex;
    width: 655px;
    height: 172px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        height: 100%;
        flex-direction: column-reverse;
        gap: 16px;
        align-items: flex-start;
        padding: 24px 28px;
    }
`;

const SectionItemContent = styled.div`
    max-width: 414px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }

    .title {
        margin-bottom: 16px;
    }

    .description {
        color: var(--text-secondary);
    }
`;

const SectionItemImage = styled.div`
    img {
        width: 116px;
        @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
            width: 60px !important;
        }
    }
`;
