import {TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import React from "react";
import {useWindowSize} from "@tools/hooks";
import styled from "styled-components";

import num1Img from "@images/find-selfworkers/GetStartSectionImage1.svg";
import num2Img from "@images/find-selfworkers/GetStartSectionImage2.svg";
import num3Img from "@images/find-selfworkers/GetStartSectionImage3.svg";

export const GetStartSection = () => {

    const {width} = useWindowSize();

    return (
        <GetStartSectionContainer>
            <GetStartSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                С чего начать
            </GetStartSectionTitle>

            <GetStartSectionItems>
                <GetStartSectionItem>
                    <GetStartSectionItemImg
                        src={num1Img}
                        alt="num-1"
                    />
                    <GetStartSectionItemTitle>
                        Разместите задание
                    </GetStartSectionItemTitle>
                    <GetStartSectionItemDescription>
                        Опишите задачу, сроки и укажите бюджет
                    </GetStartSectionItemDescription>
                </GetStartSectionItem>

                <GetStartSectionItem>
                    <GetStartSectionItemImg
                        src={num2Img}
                        alt="num-2"
                    />
                    <GetStartSectionItemTitle>
                        Ищите исполнителей
                    </GetStartSectionItemTitle>
                    <GetStartSectionItemDescription>
                        Получайте отклики на задание или предлагайте задачу понравившемуся самозанятому
                    </GetStartSectionItemDescription>
                </GetStartSectionItem>

                <GetStartSectionItem>
                    <GetStartSectionItemImg
                        src={num3Img}
                        alt="num-3"
                    />
                    <GetStartSectionItemTitle>
                        Начните сотрудничество
                    </GetStartSectionItemTitle>
                    <GetStartSectionItemDescription>
                        Договоритесь о деталях, заключите договор и приступайте к работе
                    </GetStartSectionItemDescription>
                </GetStartSectionItem>

            </GetStartSectionItems>

        </GetStartSectionContainer>
    )
};

const GetStartSectionContainer = styled.section`
    margin: 0px 0 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 100px 0 100px;
    }
`;

const GetStartSectionTitle = styled(TypographyUI.H2)`
    text-align: center;
    margin-bottom: 75px;
`

const GetStartSectionItems = styled.div`
    display: flex;
    gap: 32px;

    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
    }
`;

const GetStartSectionItem = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 360px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }
`;

const GetStartSectionItemImg = styled.img`
    width: 72px;
    height: 72px;
    margin-bottom: 32px;
`;

const GetStartSectionItemTitle = styled(TypographyUI.H4Bold)`
    padding-bottom: 12px;
`;

const GetStartSectionItemDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
`;
