import React from "react";
import {Helmet} from "react-helmet";
import {LAPTOP_MOBILE_POINTER, MOBILE_MINIMOBILE_POINTER} from "@tools/constants";
import {Layout} from "@components";

import styled from "styled-components";
import {TopSection} from "@templates/find-selfworkers/TopSection";
import {AdvantagesSection} from "@templates/find-selfworkers/AdvantagesSection";
import {WhatMarketSection} from "@templates/find-selfworkers/WhatMarketSection";
import {WhyMarketSection} from "@templates/find-selfworkers/WhyMarketSection";
import {StartMarketSection} from "@templates/find-selfworkers/StartMarketSection";
import {GetStartSection} from "@templates/find-selfworkers/GetStartSection";
import {DownloadMarketSection} from "@templates/find-selfworkers/DownloadMarketSection";
import {PossibilitiesSection} from "@templates/find-selfworkers/PossibilitiesSection";

const SelfworkersPage = () => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    Поиск самозанятого | База самозанятых граждан с данными | Сервис поиска работы самозанятых
                </title>
                <meta
                    name="description"
                    content="Поиск самозанятых исполнителей. Найти подрядчиков для бизнеса. Большая база самозанятых. Безопасно и легально, все автоматизировано"
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/find-selfworkers"
                />
            </Helmet>
            <Layout disableWidth={true}>

                <MaxWidthBox>
                    <TopSection/>
                    <AdvantagesSection/>
                </MaxWidthBox>
                <WhatMarketSection/>
                <MaxWidthBox>
                    <WhyMarketSection/>
                    <StartMarketSection/>
                    <GetStartSection/>
                    <DownloadMarketSection/>
                    <PossibilitiesSection/>
                </MaxWidthBox>

            </Layout>
        </>
    );
};

export default SelfworkersPage;

const MaxWidthBox = styled.div`

    margin: 0 auto;

    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        max-width: 1204px;
        padding: 0 32px;
    }
    @media (min-width: ${MOBILE_MINIMOBILE_POINTER + 1}px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 609px;
        padding: 0 20px;
    }
    @media (max-width: ${MOBILE_MINIMOBILE_POINTER}px) {
        max-width: 375px;
        padding: 0 16px;
    }

`;
