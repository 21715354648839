import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";

export const WhatMarketSection = () => {

    const {width} = useWindowSize();

    return (
        <WhatMarketSectionContainer>
            <WhatMarketSectionTitleContainer>
                <WhatMarketSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>Что такое Маркетплейс</WhatMarketSectionTitle>
            </WhatMarketSectionTitleContainer>

            <WhatMarketSectionItems>

                <WhatMarketSectionImage>
                    <StaticImage style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '480px' : '100%'}}
                        src="../../../images/find-selfworkers/WhatMarketSectionImage.png"
                        loading="eager"
                        placeholder="none"
                        formats={["auto", "webp"]}
                        layout="constrained"
                        objectFit="contain"
                        alt=""
                    />
                </WhatMarketSectionImage>

                <WhatMarketSectionContent>
                    <WhatMarketSectionTitle>О сервисе</WhatMarketSectionTitle>
                    <WhatMarketSectionDescription>Маркетплейс от Самозанятые.рф — сервис поиска самозанятых. Здесь вы найдете исполнителей из разных сфер для разовых задач и долгосрочных проектов</WhatMarketSectionDescription>
                </WhatMarketSectionContent>

            </WhatMarketSectionItems>

        </WhatMarketSectionContainer>
    )
};

const WhatMarketSectionContainer = styled.section`
    display: flex;
    margin-top: 120px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    padding-bottom: 100px;
    background: var(--background-second);
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px -19px 20px;
        padding-bottom: 30px;
    }
`;

const WhatMarketSectionTitleContainer = styled.div`
    width: 100%;
    justify-content: center;
    margin-bottom: 24px;
    display: flex;
    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        max-width: 1204px;
        padding: 80px 32px 0;
    }
    @media (min-width: 609px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 609px;
        padding: 60px 0px 0;
    }
    @media (max-width: 609px) {
        padding: 60px 40px 0;
    }
`;

const WhatMarketSectionTitle = styled(TypographyUI.H2)`
    margin-bottom: 24px;
`;

const WhatMarketSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;


const WhatMarketSectionItems = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        max-width: 1204px;
        padding: 60px 32px 0;
    }
    @media (min-width: 609px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 609px;
        padding: 40px 0px 0;
        gap: 40px;
    }
    @media (max-width: 609px) {
        padding: 30px 40px 0;
        gap: 40px;
    }
`;

const WhatMarketSectionImage = styled.div`
`;

const WhatMarketSectionContent = styled.div`
    width: 490px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const WhatMarketSectionContentTitle = styled(TypographyUI.H3)`
`;

const WhatMarketSectionContentDescription = styled(TypographyUI.Body1Regular)`
`;
